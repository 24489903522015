@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 240 5% 96%;
    --foreground: 0 0% 4%;

    --card: 0 0% 98%;
    --card-foreground: 0 0% 4%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 4%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 6% 90%;
    --secondary-foreground: 0 0% 9%;

    --muted: 240 6% 90%;
    --muted-foreground: 0 0% 40%;

    --accent: 240 6% 84%;
    --accent-foreground: 0 0% 8%;

    --destructive: 0 84% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 96%;
    --border: 240 6% 96%;
    --input: 240 6% 84%;
    --ring: 0 0% 4%;

    --field: 0 0% 100%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 6% 10%;
    --foreground: 0 0% 98%;

    --card: 240 6% 6%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 4%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 240 6% 20%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 6% 20%;
    --muted-foreground: 0 0% 56%;

    --accent: 240 5% 32%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 10%;
    --border: 240 4% 10%;
    --input: 240 5% 26%;
    --ring: 0 0% 83.1%;

    --field: 0 0% 0%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
